
import request from '@/utils/scm-request'

export function packagingImportView(data) {
  return request({
    url: `scm-purchase/order/package/packagingImportView`,
    method: 'post',
    data
  })
}

export function packagingImport(data, onUploadProgress = () => {
}) {
  return request({
    url: `scm-purchase/order/package/packagingImport`,
    method: 'post',
    data,
    onUploadProgress
  })
}

export function recordPage(params) {
  return request({
    url: `scm-purchase/order/package/record`,
    method: 'get',
    params
  })
}

export function statusPage(params) {
  return request({
    url: `scm-purchase/order/package/status`,
    method: 'get',
    params
  })
}

export function exceptionHandler(data) {
  return request({
    url: `scm-purchase/order/package/exceptionHandler`,
    method: 'post',
    data
  })
}

// 包装信息
export function packagingInfo(data, pager) {
  return request({
    url: `scm-purchase/packagingInfo/page?size=${pager.size}&current=${pager.current}`,
    method: 'post',
    data
  })
}

export function skuBoxInfo(data) {
  return request({
    url: `scm-purchase/packagingInfo/skuCartonInfo`,
    method: 'post',
    data
  })
}

export function updateStylePackaging(data) {
  return request({
    url: `scm-purchase/packagingInfo/update`,
    method: 'post',
    data
  })
}

export function updateInfo(id, queryType) {
  return request({
    url: `scm-purchase/packagingInfo/updateInfo/${id}/${queryType}`,
    method: 'post'
  })
}

export function checkingInfo(data) {
  return request({
    url: `scm-purchase/packagingInfo/update/checking`,
    method: 'post',
    data
  })
}

export function commitInfo(data) {
  return request({
    url: `scm-purchase/packagingInfo/update/commit`,
    method: 'post',
    data
  })
}

export function downloadTemplate(data) {
  return request({
    url: `scm-purchase/packagingInfo/downloadTemplate`,
    method: 'post',
    data,
    responseType: 'arraybuffer'
  })
}

export function importTemplate(data) {
  return request({
    url: `scm-purchase/packagingInfo/importTemplate`,
    method: 'post',
    data
  })
}
export function exportTemplate(data) {
  return request({
    url: `scm-purchase/packagingInfo/export`,
    method: 'post',
    data
  })
}

// 查看内里标图片
export function insideLabel(params) {
  return request({
    url: `scm-purchase/packagingInfo/insideLabel`,
    method: 'get',
    params
  })
}

// 发送邮件
export function sendEmail(data) {
  return request({
    url: `scm-purchase/packagingInfo/sendEmail`,
    method: 'post',
    data
  })
}

// 包装代码
export function packagingCode(data, pager) {
  return request({
    url: `scm-purchase/packagingCode/page?size=${pager.size}&current=${pager.current}`,
    method: 'post',
    data
  })
}
// 新增修改查看
export function insertPackagingCode(data) {
  return request({
    url: `scm-purchase/packagingCode/insert`,
    method: 'post',
    data
  })
}

export function updatePackagingCode(data) {
  return request({
    url: `scm-purchase/packagingCode/update`,
    method: 'post',
    data
  })
}

export function PackagingCodeInfo(id) {
  return request({
    url: `scm-purchase/packagingCode/detail/${id}`,
    method: 'post',
    id
  })
}
// 版本记录
export function versionRecord(id) {
  return request({
    url: `scm-purchase/packagingCode/versionRecord/${id}`,
    method: 'post',
    id
  })
}
// 下载附件
export function packagingCodeDownLoad(data, id) {
  return request({
    url: `scm-purchase/packagingCode/download/${id}`,
    method: 'post',
    data
  })
}

// 获取包装代码
export function getPackagingCode(data) {
  return request({
    url: `scm-purchase/packagingCode/dict`,
    method: 'post',
    data
  })
}
export function PackagingExport(data) {
  return request({
    url: `scm-purchase/packagingCode/export`,
    method: 'post',
    data
  })
}


// VOC打标分页查询
export function getVocPage(data, params) {
  return request({
    url: `scm-purchase/voc/page`,
    method: 'post',
    data,
    params
  })
}
// 新增-VOC打标
export function saveVocList(data) {
  return request({
    url: `scm-purchase/voc/save`,
    method: 'post',
    data,
  })
}

// 查看明细
export function getVocDetail(id) {
  return request({
    url: `scm-purchase/voc/getDetail/${id}`,
    method: 'get',
  })
}
// 查看日志
export function getVocLog(id) {
  return request({
    url: `scm-purchase/voc/getLog/${id}`,
    method: 'get',
  })
}

// 修改-VOC打标
export function updateVocList(data) {
  return request({
    url: `scm-purchase/voc/update`,
    method: 'post',
    data
  })
}

// 导出
export function vocExport(data) {
  return request({
    url: `scm-purchase/voc/export`,
    method: 'post',
    data
  })
}

// 删除-voc
export function deleteVoc(data) {
  return request({
    url: `scm-purchase/voc/delete`,
    method: 'delete',
    data
  })
}

// 导入-预览
export function previewImport(data) {
  return request({
    url: `scm-purchase/voc/importView`,
    method: 'post',
    data
  })
}

// 导入-提交
export function submitImport(data) {
  return request({
    url: `scm-purchase/voc/importSubmit`,
    method: 'post',
    data
  })
}
// VOC导入模板下载
export function downloadVOCTemplate(data) {
  return request({
    url: `scm-purchase/voc/downloadTemplate`,
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}

// po单号下拉框（VOC打标用）
export function getVOCPOList(styleName, vendorId) {
  return request({
    url: `scm-purchase/basic/purchaseOrderBasic/poCodeListForVoc/${styleName}/${vendorId}`,
    method: 'get'
  })
}

// 获取最新的po单对应的供应商Id
export function getLatestVendorId(styleName) {
  return request({
    url: `scm-purchase/voc/getLatestVendorId/${styleName}`,
    method: 'get'
  })
}