<template>
  <div>
    <el-dialog
      title="日志"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="140px">
          <el-form-item label="Style:" :class="$i18n.locale" prop="styleName">
              <el-select
                  v-model="form.styleId"
                  style="width: 400px"
                  :disabled="true"
              >
                  <el-option
                      v-for="item in styleOptions"
                      :key="item.id"
                      :label="item.styleName"
                      :value="item.id"
                  />
              </el-select>
          </el-form-item>
          <el-form-item label="供应商" :class="$i18n.locale" prop="vendorName">
              <el-select
                  v-model="form.vendorId"
         
                  style="width: 400px"
                  :disabled="true"
              >
                  <el-option
                      v-for="item in vendorOptions"
                      :key="item.id"
                      :label="item.vendorName"
                      :value="item.id"
                  />
              </el-select>
          </el-form-item>
      </el-form>
      <Table
        ref="versionTable"
        :table-data="detailList"
        max-height="600px"
        :columns="templateTableColumns"
        border
        :loading="tableLoading"
      >
      </Table>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
        <el-button @click="$emit('input',false)" type="primary" plain>关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { templateTableColumns } from './columns'
import Table from '@/components/Table'
import { getVocLog } from '@/api/package'

export default {
  components: { Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    styleOptions: {
      type: Array,
      default: () => []
    },
    vendorOptions: {
      type: Array,
      default: () => []
    },
    detailData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form: {
        styleId: '',
        vendorId: '',
        detailList: []
      },
      detailList: [],
      templateTableColumns,
      tableLoading: false,
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this._queryVocDetail(this.detailData.id)
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async _queryVocDetail(id) {
      try{
          this.tableLoading = true
          const { datas } = await getVocLog(id)
          this.form = datas
          this.detailList = Array.isArray(datas.detailList) ? datas.detailList.map((v,j)=> ({...v, id: j+1})) : []
          this.tableLoading = false
        } catch(err){
          this.tableLoading = false
        }
    },
  }
}
</script>

<style scoped lang="scss">
</style>
