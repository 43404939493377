<template>
  <div class="app-container">
    <el-form
      ref="ruleForm"
      :model="form"
      label-width="134px"
      style="display: flex; flex-wrap: wrap; margin-top: 15px"
    >
      <div class="el-lt" style="width: 97%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.styleIdList"
                multiple
                value-key="id"
                clearable
                filterable
                style="width:100%;"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域">
              <el-select v-model="form.areaCodeList" multiple :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option
                  v-for="item in _getAllCommodityDict('AREA_CODE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="VOC改善完成日期" :class="$i18n.locale">
              <el-date-picker
                v-model="form.improveFinishDate"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select v-model="form.modifyByIdList" multiple clearable filterable style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作日期" :class="$i18n.locale">
              <el-date-picker
                v-model="form.operateTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="el-rt header_btn">
        <div>
          <el-button
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="handleAdd('add')"
          >
            新增
          </el-button>
          <el-button
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="importVisible = true"
          >
            批量导入
          </el-button>
          <el-button
            v-permission="'delete'"
            type="danger"
            class="ml-4"
            :loading="loading"
            @click="handleDel"
          >
            删除
          </el-button>
        </div>

        <div class="row-center">
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
          <el-button
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="queryClick(1)"
          >查询</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button
            type="primary"
            class="ml-4"
            :loading="exportLoading"
            @click="_exportDetail"
          >导出</el-button>
        </div>
      </div>
    </el-form>

    <Table
      ref="packagingTable"
      :table-data="tableDatas"
      max-height="600px"
      :columns="getColumns"
      :page-obj="pager"
      :loading="tableLoading"
      border
      :check="true"
      @handleSelectionChange="handleSelectionChange"
    >
      <el-table-column slot="operate" label="操作" width="120" align="center">
        <template slot-scope="{row}">
          <el-button
            :loading="loading"
            type="text"
            size="small"
            @click="handleAdd('edit', row)"
          >
            修改
          </el-button>
          <el-button
            :loading="loading"
            type="text"
            size="small"
            @click="showVersion(row)"
          >
            查看日志
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end :page-sizes="pageSizes" @pagination="_purchasePage" />
    </div>
    <VersionList
      v-model="versionVisible"
      :style-options="styleOptions"
      :vendor-options="vendorOptions"
      :detail-data="detailData"
    />
    <EditVOC
      v-model="DialogVisible"
      :style-options="styleOptions"
      :vendor-options="vendorOptions"
      :data="purchaseData"
      :current-type="currentType"
      :detail-data="detailData"
      @refresh="handleQuery"
    />
    <ImportFile v-model="importVisible" @refresh="queryClick" />
  </div>
</template>
<script>
import { getVocPage, vocExport, deleteVoc } from '@/api/package'
import { styleListConfig } from '@/api/configuration'
import { usersList, queryVendorList } from '@/api/scm-api'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import { downloads } from '@/utils'
import { getColumns } from './components/columns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { omit } from 'lodash'
import VersionList from './components/versionList.vue'
import EditVOC from './components/EditVOC.vue'
import ImportFile from './components/ImportFile.vue'

export default {
  components: {
    Paging,
    Table,
    VersionList,
    EditVOC,
    ImportFile
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      multipleSelection: [],
      detailData: {},
      currentType: '',
      purchaseData: {
        contractCode: '',
        file: '',
        notFufilledPoCodeList: [],
        frameworkContractExpiryDate: '',
        contractType: '',
        originSignDate: ''
      },
      DialogVisible: false,
      vendorOptions: [],
      showRow: true, // 切换显示input
      useroptions: [],
      styleOptions: [],
      getColumns,
      pageSizes: [10, 20, 30, 40, 50],
      form: {
        vendorIdList: [],
        operateTime: [],
        styleIdList: [],
        areaCodeList: [],
        modifyByIdList: [],
        improveFinishDate: []
      },
      tableLoading: false,
      loading: false,
      exportLoading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableDatas: [],
      importVisible: false,
      standardVisible: false,
      versionVisible: false,
      versionDatas: [],
      versionId: 0
    }
  },
  computed: {
    queryParams() {
      const { operateTime, improveFinishDate } = this.form
      const [modifyTimeStart, modifyTimeEnd] = operateTime || []
      const [improveFinishDateStart, improveFinishDateEnd] = improveFinishDate || []
      return Object.assign({}, omit(this.form, ['operateTime', 'improveFinishDate']),
        {
          modifyTimeStart, modifyTimeEnd,
          improveFinishDateStart, improveFinishDateEnd
        })
    }
  },
  mounted() {
    this.queryClick()
    this._usersList()
    this._queryStyleList()
    this._queryVendorList()
  },
  methods: {
    handleDel() {
      const { multipleSelection } = this
      if (Array.isArray(multipleSelection) && multipleSelection.length) {
        this.$confirm(`你选中了${multipleSelection.length}条数据进行删除，该操作无法撤销，请确认！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const idList = multipleSelection.map(item => item.id)
          await deleteVoc({ ids: idList })
          this.queryClick(1)
          this.multipleSelection = []
          trackDialogEvent(handleBreadTitle(this.$route) + '删除')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.$message({
          message: '请至少选择一条数据删除',
          type: 'warning'
        })
        return false
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleQuery() {
      this.pager.current = 1
      this.queryClick()
    },
    handleAdd(type, row) {
      this.currentType = type
      this.DialogVisible = true
      this.detailData = row
    },
    // 日志
    async showVersion(row) {
      try {
        this.loading = true
        this.detailData = row
        this.versionVisible = true
      } finally {
        this.loading = false
      }
    },
    async queryClick(flag) {
      try {
        this.loading = true
        this.tableLoading = true
        flag && flag === 1 ? (this.pager.current = 1) : ''

        const {
          datas: { pager, records }
        } = await getVocPage(this.queryParams, this.pager)
        this.pager = pager
        this.tableDatas = records

        this.loading = false
        this.tableLoading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        this.tableLoading = false
      }
    },
    async _exportDetail() {
      try {
        this.exportLoading = true
        const params = {
          ...this.queryParams,
          idList: this.multipleSelection?.map(v => v.id) || []
        }
        await vocExport(params)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
        this.exportLoading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.exportLoading = false
      }
    },
    resetClick() {
      // 重置表单
      this.form = {}
      this.queryClick(1)
    },

    async _purchasePage(pagers) {
      this.pager = pagers
      this.queryClick()
    },

    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await styleListConfig()
      this.styleOptions = datas
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    }
  }
}
</script>
  <style lang="scss" scope>

  .style_w100 {
    width: 100%;
  }
  .header_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .header_replenishCycle {
    margin-bottom: 0
  }

  </style>
