export const getColumns = [
  // {
  //   type: 'selection',
  //   width: 120
  // },
  {
    prop: 'styleName',
    label: 'Style',
    width: 120
  },
  {
    prop: 'vendorName',
    label: '供应商',
    width: 160
  },
  {
    prop: 'areaName',
    label: '区域',
    width: 120
  },
  {
    prop: 'improveFinishDate',
    label: 'VOC改善完成日期',
    width: 160
  },
  {
    prop: 'improveDescription',
    label: '改善说明',
    minWidth: 120
  },
  {
    prop: 'originPurchaseOrderCode',
    label: '改善起始PO',
    minWidth: 120
  },
  {
    prop: 'createByName',
    label: '操作人',
    width: 120
  },
  {
    prop: 'createTime',
    label: '操作时间',
    width: 200
  },
  {
    slot: 'operate',
    label: '操作'
  },
]

export const templateTableColumns = [
  { label: 'VOC改善完成日期', prop: 'improveFinishDate' },
  { label: '改善说明', prop: 'improveDescription' },
  { label: '改善起始PO', prop: 'originPurchaseOrderCode' },
  { label: '操作人', prop: 'createByName' },
  { label: '操作时间', prop: 'createTime' }
]

