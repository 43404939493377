<template>
    <div>
      <el-dialog :title="disabledFlage ? '修改' : '新增'" :visible.sync="visible" width="500px" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules" label-width="140px">
            <el-form-item label="Style:" :class="$i18n.locale" prop="styleName">
                <el-select
                    v-model="form.style"
                    value-key="id"
                    style="width: 100%"
                    filterable
                    @change="styleChange"
                    :disabled="disabledFlage"
                >
                    <el-option
                        v-for="item in styleOptions"
                        :key="item.id"
                        :label="item.styleName"
                        :value="item"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="供应商" :class="$i18n.locale" prop="vendorName">
                <el-select
                    v-model="form.vendor"
                    filterable
                    value-key="id"
                    style="width: 100%"
                    :placeholder="$t('page.selectPlaceholder')"
                    @change="vendorChange"
                    :disabled="disabledFlage"
                >
                    <el-option
                        v-for="item in vendorOptions"
                        :key="item.id"
                        :label="item.vendorName"
                        :value="item"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="VOC改善完成日期" :class="$i18n.locale" prop="improveFinishDate">
                <el-date-picker
                    v-model="form.improveFinishDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    :picker-options="pickerOptions"
                    style="width: 100%"
                    @change="dateChange"
                />
            </el-form-item>
            <el-form-item label="改善起始PO" :class="$i18n.locale" v-if="disabledFlage">
                <el-select
                    v-model="form.originPurchaseOrderCode"
                    filterable
                    clearable
                    style="width: 100%"
                    :placeholder="$t('page.selectPlaceholder')"
                >
                    <el-option
                        v-for="item in VOCPOList"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                </el-select>
              </el-form-item>
            <el-form-item label="改善说明" :class="$i18n.locale">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="form.improveDescription"
                    maxlength="1000"
                    show-word-limit
                >
                </el-input>
              </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="$emit('input',false)">取消</el-button>
          <el-button type="primary" :loading="submitLoading" @click="handleSubmit()">提交</el-button>
        </span>
      </el-dialog>
    </div>
</template>
  
<script>
  import { getPOContractLogList } from '@/api/scm-api'
  import Select from '@/components/Selection'
  import commodityInfoDict from '@/mixin/commodityInfoDict.js'
  import { omit } from 'lodash'
  import { getVocDetail, updateVocList, saveVocList, getVOCPOList, getLatestVendorId } from '@/api/package'
     
  export default {
    components: { Select },
    mixins: [commodityInfoDict],
    props: {
      value: {
        type: Boolean,
        default: false
      },
      currentType: {
        type: String,
        default: ''
      },
      styleOptions: {
        type: Array,
        default: () => []
      },
      vendorOptions: {
        type: Array,
        default: () => []
      },
      detailData: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        pickerOptions: {
            disabledDate(time) {
             return time.getTime() > Date.now()
            }
        },
        submitLoading: false,
        disabledFlage: false,
        VOCPOList: [],
        form: { 
            style: {},
            styleId: '',
            styleName: '',
            vendorName: '',
            vendorId: '',
            originPurchaseOrderCode: '',
            vendor: {},
            improveDescription: '',
            improveFinishDate: ''
        },
        rules: {
          styleName: { required: true, message: '必填', trigger: 'change' },
          vendorName: { required: true, message: '必填', trigger: 'change' },
          improveFinishDate: { required: true, message: '必填', trigger: 'change' },
        }
      }
    },
    computed: {
      visible: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      },
    },
    watch: {
      'visible'(val) {
        if (!val) {
          this.form = this.$options.data.call(this).form
        }
        this.$refs.form && this.$refs.form.clearValidate()
        if(val) {
            const { currentType } = this
            this.disabledFlage = currentType ==='edit'
            if(currentType ==='edit') {
                this._queryVocDetail(this.detailData.id)
            }
        } else {
            this.VOCPOList = []
        }
      },
    //   currentType(val) {
    //     // this.disabledFlage = val ==='edit'
    //     // if(val ==='edit') {
    //     //     this._queryVocDetail(this.detailData.id)
    //     // }
    //   }
    },
    created() {
  
    },
    mounted() {
      
    },
    methods: {
      dateChange() {
        this.form.originPurchaseOrderCode = ''
      },
      styleChange({id, styleName}) {
        Object.assign(this.form, {
            styleName:styleName,
            styleId: id,
            vendorName: '',
            vendorId: '',
            vendor: {  }
        })

        this._getLatestVendorId(styleName)
     },
     vendorChange({id, vendorName}) {
        Object.assign(this.form, {
            vendorName,
            vendorId: id
        })
     },
      async getPOList(poContractCode) {
        const { datas } = await getPOContractLogList({ poContractCode })
        this.poList = datas.map(item => { return { ...item, label: item, val: item } })
      },
      handleSubmit() {
        this.$refs.form.validate(async valid => {
        //   console.log('valid: ', this.form, valid)
          if (valid) {
            try {
            this.submitLoading = true
            const params = Object.assign({}, omit(this.form, ['style', 'vendor'])) 
            const { code, msg } = this.disabledFlage ? await updateVocList(params) : await saveVocList(params)
            if (code === 0) {
                this.$notify({
                title: msg,
                message: msg,
                type: 'success'
                })
                this.$emit('input', false)
                this.$emit('refresh')
            }
            } catch (err) {
             console.log(err)
            } finally {
             this.submitLoading = false
            }
          }
        })
      },
      async _queryVocDetail(id) {
        const { datas } = await getVocDetail(id)
        const { styleId, styleName, vendorId, vendorName } = datas
        Object.assign(this.form, datas, {
            style:{
                id: styleId,
                styleName
            },
            vendor: {
                id: vendorId,
                vendorName
            }
        })
        this._getVOCPOList(styleName, vendorId)
      },
      async _getVOCPOList(styleName, vendorId) {
        try{
            const { datas } = await getVOCPOList(styleName, vendorId)
            if(Array.isArray(datas)) {
                this.VOCPOList = datas
            }
        } catch(err) {
        }
      },
      async _getLatestVendorId(styleName) {
        try{
            const { datas } = await getLatestVendorId(styleName)
            if(datas) {
             const {id='', vendorName=''} = this.vendorOptions.filter(v => v.id == datas)[0] || {}
             Object.assign(this.form, {
                vendorName,
                vendorId: id,
                vendor: {
                    id,
                    vendorName
                }
             })
            }
        } catch(err) {
        }
      },
    }
  }
</script>
  
<style scoped lang="scss">
 
</style>
  